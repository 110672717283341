import React from "react";
import PageLayout from "../components/page-layout"
import SEO from "../components/seo"
import 'twin.macro'

export default function Voorwaarden() {
    return (
        <PageLayout>
            <SEO title="Algemene voorwaarden" />

            <h1 tw="text-4xl font-bold text-center pb-8">Algemene voorwaarden</h1>
            <h3 tw="text-lg font-bold pt-6 pb-2">Artikel 1. Toepassing algemene voorwaarden</h3>
            <p tw="text-sm py-2 leading-relaxed">1.1. Deze algemene voorwaarden gelden op alle aanbiedingen van en op alle overeenkomsten met Amiko BVBA, ook al staan er strijdige bepalingen vermeld op documenten van de klant. Door het plaatsen van een bestelling aanvaardt de klant de algemene voorwaarden van Amiko.</p>
            <h3 tw="text-lg font-bold pt-6 pb-2">Artikel 2. Aanbiedingen en offertes – orderbevestiging</h3>
            <p tw="text-sm py-2 leading-relaxed">2.1. Alle aanbiedingen en offertes van Amiko zijn vrijblijvend tot op het ogenblik van de aanvaarding door de klant. De overeenkomst komt tot stand wanneer de klant de offerte ongewijzigd binnen de acht dagen voor akkoord ondertekent en terugbezorgdt aan Amiko (per post, digitaal of persoonlijk). Elke bestelling of orderbevestiging door de klant, verbindt de klant aan de overeenkomst. De overeenkomst vervangt alle eerder afgesloten mondelinge en/of schriftelijke akkoorden. De uitvoering van de bestelling start vanaf ontvangst van het voorschot.</p>
            <p tw="text-sm py-2 leading-relaxed">2.2 Alle wijzigingen in scope/functionaliteiten tijdens en/of na het traject worden, in regie uitgevoerd aan het op dat ogenblik geldende uurtarief, tenzij anders afgesproken.</p>
            <h3 tw="text-lg font-bold pt-6 pb-2">Artikel 3. Annulatie van de bestelling</h3>
            <p tw="text-sm py-2 leading-relaxed">3.1. De annulatie van een bestelling door de klant is mogelijk zolang Amiko haar werkzaamheden nog niet heeft aangevat en mits betaling van een schadevergoeding van 20% van de overeengekomen prijs, met een minimum van 500 EUR.</p>
            <h3 tw="text-lg font-bold pt-6 pb-2">Artikel 4. Levering</h3>
            <p tw="text-sm py-2 leading-relaxed">4.1. De datum van levering wordt slechts als aanwijzing opgegeven en bindt Amiko niet. Vertraging in de levering geeft de klant geen recht op schadevergoeding of prijsvermindering, noch op ontbinding van de overeenkomst.</p>
            <p tw="text-sm py-2 leading-relaxed">4.2. Indien de partijen uitdrukkelijk een bindende leveringstermijn zijn overeengekomen, wordt deze termijn verlengd indien de klant in gebreke blijft om informatie, documenten, originelen of beelden (tijdig) over te maken en de verbeterde proeven (tijdig) te aanvaarden, of indien de klant bijkomende bestellingen plaatst.</p>
            <h3 tw="text-lg font-bold pt-6 pb-2">Artikel 5. Risico</h3>
            <p tw="text-sm py-2 leading-relaxed">5.1. Alle goederen die toebehoren aan de klant en zich bij Amiko bevinden, worden er bewaard op risico van de klant.</p>
            <h3 tw="text-lg font-bold pt-6 pb-2">Artikel 6. Betalingsmodaliteiten</h3>
            <p tw="text-sm py-2 leading-relaxed">6.1. Tenzij anders overeengekomen, zijn de facturen van Amiko contant betaalbaar. Betwistingen dienen binnen de zeven werkdagen na verzending van de factuur per aangetekend schrijven aan Amiko kenbaar gemaakt te worden. Een betwisting kan in geen geval een uitstel of schorsing van betaling verantwoorden.</p>
            <p tw="text-sm py-2 leading-relaxed">6.2. Alle facturen zijn betaalbaar op hun vervaldag door overschrijving op het rekeningnummer van Amiko. Elke betaling wordt aangerekend op de oudste vervallen factuur, en eerst op de verschuldigde intresten en kosten. Toegestane kortingen vervallen bij het niet respecteren van de algemene verkoopsvoorwaarden.</p>
            <p tw="text-sm py-2 leading-relaxed">6.3. Indien de klant niet overgaat tot betaling binnen 8 dagen na ontvangst van een aanmaning hiertoe door Amiko, is de klant aan Amiko een nalatigheidsintrest verschuldigd aan de intrestvoet bepaald in artikel 5 van de Wet van 02/08/2002 ter Bestrijding van de Betalingsachterstand bij Handelstransacties. Er wordt ook een forfaitaire schadevergoeding in rekening gebracht van 10% van het factuurbedrag met een minimum van 125 EUR. De verschuldigde intrest wordt berekend vanaf de datum van aanmaning tot aan de volledige betaling.  Bovendien behoudt Amiko zich het recht voor om de verdere uitvoering van haar verbintenissen op te schorten totdat de klant de vervallen facturen heeft betaald. Elke vertraging in de betaling door de klant maakt alle verschuldigde sommen ineens opeisbaar. De klant mag in dat geval de door Amiko gemaakte creaties niet gebruiken.</p>
            <p tw="text-sm py-2 leading-relaxed">6.4 Oplevering van uitgevoerde werken kan pas gebeuren na betaling van 90% van de facturen. De overdracht van de broncode en de intellectuele eigendomsrechten verbonden aan het visuele ontwerp van de website, kunnen pas plaatsvinden nadat de klant de volledige som van het contract betaald heeft.</p>
            <p tw="text-sm py-2 leading-relaxed">6.5 Webprojecten die on hold zijn gezet door de klant geven geen aanleiding tot schorsing van betaling.</p>
            <p tw="text-sm py-2 leading-relaxed">6.6. Amiko is gerechtigd de overeenkomst met onmiddellijke ingang te beëindigen en/of de toegang tot de Diensten (vb website) geheel of gedeeltelijk en al dan niet tijdelijk te blokkeren, indien de klant één of meer van zijn verplichtingen voortspruitende uit deze Overeenkomst geheel of gedeeltelijk niet nakomt (zoals een niet-betaling van de factuur) zonder dat de Klant aanspraak kan maken op terugbetaling van vooruitbetaalde vergoedingen of enerlei schadevergoeding. Amiko zal in elk geval de Klant hiervan op de hoogte stellen. Verder is Amiko gerechtigd de Overeenkomst zonder nadere ingebrekestelling en van rechtswege met onmiddellijke ingang te beëindigen in het geval de Klant in staat van faillissement is verklaard, de Klant een gerechtelijk akkoord heeft gevraagd of aanvaard, of meer algemeen de Klant in staking van betaling verkeert.</p>
            <h3 tw="text-lg font-bold pt-6 pb-2">Artikel 7. Aansprakelijkheid – Algemeen</h3>
            <p tw="text-sm py-2 leading-relaxed">7.1. Amiko verbindt zich ertoe alle te verstrekken diensten met zorg uit te voeren. Alle prestaties van Amiko zijn middelenverbintenissen. Amiko is niet aansprakelijk voor fouten in de uitvoering te wijten aan onvoldoende of verkeerde input door de klant. Na ontwikkeling van een website voorzien wij in een transparante overdracht en installatie. We voorzien ook een garantieperiode van 4 weken na testoplevering, voor het verwerken van technische bugs. De testoplevering geldt als voorlopige aanvaarding door de klant. Zonder schriftelijk tegenbericht wordt deze voorlopige aanvaarding na 4 weken definitief.</p>
            <p tw="text-sm py-2 leading-relaxed">7.2. Amiko kan niet aansprakelijk gesteld worden voor enige fout (zelfs een grove fout) van haar of haar aangestelden, behoudens ingeval van bedrog. Amiko zal, wat ook de oorzaak, de vorm of het voorwerp is van de vordering waarbij de aansprakelijkheid wordt ingeroepen, in geen geval aansprakelijk kunnen worden gesteld voor enige gevolgschade zoals bijvoorbeeld verlies van verwachte winst, daling van omzet, verhoogde operationele kosten, verlies van cliënteel, welke de klant of derden zouden lijden ten gevolge van enige fout of nalatigheid van Amiko of een aangestelde.</p>
            <p tw="text-sm py-2 leading-relaxed">7.3. De aansprakelijkheid van Amiko met betrekking tot aan de klant geleverde diensten is in elk geval beperkt tot ofwel de terugbetaling van de door de klant betaalde prijs, ofwel het opnieuw uitvoeren van de diensten, naar keuze van Amiko. De totale aansprakelijkheid van Amiko, zal nooit meer bedragen dan de prijs die door de klant aan Amiko werd betaald voor de diensten die aanleiding gaven tot het schadegeval.</p>
            <p tw="text-sm py-2 leading-relaxed">7.4. Wat de diensten afkomstig van derde leveranciers betreft, aanvaardt Amiko geen enkele aansprakelijkheid boven of anders dan de aansprakelijkheid die de derde leveranciers bereid zijn te aanvaarden voor hun producten of diensten.</p>
            <p tw="text-sm py-2 leading-relaxed">7.5. Amiko kan niet aansprakelijk gehouden worden voor het gebruik van foto’s of lettertypes geleverd en/of goedgekeurd door de klant.</p>
            <h3 tw="text-lg font-bold pt-6 pb-2">Artikel 8. Aansprakelijkheid software</h3>
            <p tw="text-sm py-2 leading-relaxed">8.1. Onverminderd artikel 7, geldt inzake software het volgende: de feilloze werking van een computerconfiguratie (het geheel van hardware en software) kan nooit volledig worden gegarandeerd, dit zowel wegens externe factoren (stroomuitval of –storing, blikseminslag,…) als wegens factoren eigen aan de computerconfiguratie (defecten, netwerkstoringen, onontdekte fouten in systeem- en toepassingssoftware,…), zodat o.m. onverwacht verlies van (zelfs alle) programma’s en/of gegevens kan optreden. De klant verbindt zich ertoe aangepaste mechanismen voor de beveiliging, de bewaring en de herstelling van gegevens te installeren.</p>
            <h3 tw="text-lg font-bold pt-6 pb-2">Artikel 9. Intellectuele eigendomsrechten</h3>
            <p tw="text-sm py-2 leading-relaxed">9.1. Onder Intellectuele Eigendomsrechten wordt verstaan: alle intellectuele, industriële en andere eigendomsrechten (ongeacht of deze geregistreerd zijn of niet), met inbegrip van, maar niet beperkt tot, auteursrechten, naburige rechten, merken, handelsnamen, logo’s, tekeningen, modellen of aanvragen tot registratie als tekening of model, octrooien, aanvragen tot octrooien, domeinnamen, know-how, alsmede rechten op databanken, computerprogramma’s en halfgeleiders.</p>
            <p tw="text-sm py-2 leading-relaxed">9.2. Beide partijen aanvaarden dat het concept van een website (m.n. de opbouw van de schermen van de website, hoofdnavigatie) in beginsel niet zal worden beschermd door Intellectuele Eigendomsrechten. De Klant kan dan ook een gelijkaardige opbouw terugvinden bij andere door Amiko ontwikkelde sites.</p>
            <p tw="text-sm py-2 leading-relaxed">9.3. De Intellectuele Eigendomsrechten verbonden aan het visuele ontwerp van de door Amiko gecreëerde website worden overgedragen aan de klant, na betaling (art. 6.4). Deze overdracht geldt in de meest volledige omvang, voor alle exploitatiewijzen en –vormen, voor de gehele duur van het desbetreffende recht en voor de gehele wereld. Daarenboven krijgt de klant een niet-exclusieve gebruikslicentie op alle voor de website gebruikte codes. Deze gebruikslicentie geldt voor de duur van de bescherming van de code door het auteursrecht en voor de gehele wereld. Indien de website evenwel foto’s of tekeningen bevat die niet door de klant werden aangeleverd, maar door Amiko werden gehaald van een website die online foto’s en illustraties ter beschikking stelt al dan niet tegen betaling, dan is de gebruikslicentie die de klant op deze foto’s en tekeningen verkrijgt, afhankelijk van de voorwaarden, bepaald op de website van deze online bibliotheek. In de regel zal deze gebruikslicentie niet exclusief zijn. Amiko verstrekt geen enkele garantie met betrekking tot deze foto’s en illustraties.</p>
            <p tw="text-sm py-2 leading-relaxed">9.4. De Intellectuele Eigendomsrechten verbonden aan het CMS (d.i. de nodige software voor het beheer van de inhoud van de website) behoren exclusief toe aan Amiko of een derde waarmee Amiko hieromtrent een overeenkomst heeft gesloten. Mits betaling van een jaarlijkse licentievergoeding, bepaald in de offerte van Amiko, en onder opschortende voorwaarde van de volledige betaling van deze vergoeding, verkrijgt de klant een niet-exclusieve, niet-overdraagbare gebruikslicentie op deze software. Het is de klant verboden om sublicenties aan derden toe te kennen, dan wel de software op enigerlei wijze aan derden ter beschikking te stellen, mee te delen, te gebruiken ten behoeve van derden of te commercialiseren.</p>
            <p tw="text-sm py-2 leading-relaxed">9.5. De klant zal de Intellectuele Eigendomsrechten van Amiko te allen tijde respecteren en redelijke inspanningen leveren om die rechten te beschermen. De klant zal Amiko onmiddellijk in kennis stellen van iedere inbreuk door derden op de Intellectuele Eigendomsrechten van Amiko waarvan hij kennis neemt.</p>
            <h3 tw="text-lg font-bold pt-6 pb-2">Artikel 10. Hostingdiensten</h3>
            <p tw="text-sm py-2 leading-relaxed">10.1. Voor de hosting werkt Amiko samen met een gespecialiseerde hostingpartner. Een beschrijving van de hosting diensten en de aansprakelijkheid van deze partner is opgenomen in de Service Level Agreement (SLA) van deze hostingpartner. Deze SLA kan worden aangepast of gewijzigd door de hostingpartner. Op eerste verzoek van de klant bezorgt Amiko de klant een kopie van de actuele versie van de SLA.</p>
            <p tw="text-sm py-2 leading-relaxed">10.2. De hostingdiensten worden door Amiko aan de klant verstrekt per kalenderjaar, mits betaling door de klant van de verschuldigde vergoeding. De actuele prijslijst kan worden opgevraagd op de website van Amiko en wordt jaarlijks aangepast. Indien de klant deze dienst wenst op te zeggen, dient hij dit te doen door uiterlijk tegen 1 december zijn opzeg aan Amiko over te maken per aangetekende brief. Bij laattijdige opzeg zal de klant de vergoeding voor het volgende kalenderjaar verschuldigd zijn.</p>
            <h3 tw="text-lg font-bold pt-6 pb-2">Artikel 11. Domeinnaam</h3>
            <p tw="text-sm py-2 leading-relaxed">11.1. Indien de klant een domeinnaam bestelt via Amiko, dan komen de rechten verbonden aan deze domeinnaam uitsluitend toe aan de klant. Amiko staat als agent in voor het beheer van de domeinnaam voor zover de klant hiertoe de verschuldigde jaarlijkse vergoeding betaalt aan Amiko. Deze beheerovereenkomst is van onbepaalde duur en kan per aangetekende brief worden opgezegd uiterlijk één maand voor de verjaardag van de domeinnaamregistratie.</p>
            <h3 tw="text-lg font-bold pt-6 pb-2">Artikel 12. Onderhoudscontract</h3>
            <p tw="text-sm py-2 leading-relaxed">12.1 Na de contractuele garantieperiode van 4 weken werken we verder onder een onderhoudscontract/servicecontract. Zo’n onderhoudscontract laat ons toe om een vast aantal uren te reserveren in de planning om wijzigingen en ondersteuning te leveren tegen een vaste kostprijs. Het contract is 1 jaar geldig, tenzij anders werd overeengekomen.</p>
            <h3 tw="text-lg font-bold pt-6 pb-2">Artikel 13. Beëindiging van de overeenkomst</h3>
            <p tw="text-sm py-2 leading-relaxed">13.1. Indien de klant zich schuldig maakt aan een zware contractuele wanprestatie die de klant niet herstelt binnen de 8 dagen na ontvangst van een ter post aangetekende ingebrekestelling, heeft Amiko het recht om ofwel (i) de overeenkomst te schorsen totdat de klant zijn verbintenissen is nagekomen, ofwel (ii) de overeenkomst met onmiddellijke ingang te beëindigen. De niet-betaling van een of meerdere facturen op hun vervaldag, zal altijd worden beschouwd als een zware contractuele wanprestatie.</p>
            <p tw="text-sm py-2 leading-relaxed">13.2. Bij beëindiging van de overeenkomst zal de klant alle door Amiko verleende diensten betalen, alsook de kosten die Amiko moet maken als gevolg van deze beëindiging, vermeerderd met een forfaitaire schadevergoeding van 30% van het bedrag dat Amiko nog had kunnen factureren aan de klant indien de overeenkomst volledig zou zijn uitgevoerd. Het eventueel betaald voorschot blijft hoe dan ook verworven voor Amiko. Bovendien behoudt Amiko het recht om een hogere schadevergoeding te vorderen indien zij bewijst dat haar werkelijk geleden schade groter is dan de forfaitaire schade zoals hierboven bepaald.</p>
            <p tw="text-sm py-2 leading-relaxed">13.3. Niettemin aanvaardt elke partij om aan de andere partij een redelijke termijn toe te kennen om haar eventuele tekortkomingen te verhelpen, en om steeds eerst te zoeken naar een minnelijke regeling.</p>
            <h3 tw="text-lg font-bold pt-6 pb-2">Artikel 14. Geheimhoudingsplicht</h3>
            <p tw="text-sm py-2 leading-relaxed">14.1. Partijen verbinden zich ertoe de commerciële en technische informatie en de bedrijfsgeheimen die zij vernemen van de andere partij, zelfs na de beëindiging van de overeenkomst, geheim te houden en enkel te gebruiken voor de uitvoering van de overeenkomst.</p>
            <h3 tw="text-lg font-bold pt-6 pb-2">Artikel 15. Verwerking persoonsgegevens</h3>
            <p tw="text-sm py-2 leading-relaxed">15.1. Voor zover de klant persoonsgegevens verwerkt op de server van Amiko, heeft Amiko de hoedanigheid van verwerker. De klant heeft de hoedanigheid van verantwoordelijke voor de verwerking van persoonsgegevens in de zin van de Wet Verwerking Persoonsgegevens. De klant verklaart de verplichtingen die rusten op de verantwoordelijke voor de verwerking, opgenomen in deze wet, integraal na te leven.</p>
            <p tw="text-sm py-2 leading-relaxed">15.2. In het kader van de diensten voor de klant, verwerkt Amiko persoonsgegevens van de door de klant opgegeven contactpersonen. De contactgegevens van deze personen worden verwerkt voor het doeleinde ‘klantenbeheer’, vb. om met de klant in contact te treden m.b.t. de diensten. De contactpersonen hebben een recht van toegang en verbetering m.b.t. hun gegevens.</p>
            <h3 tw="text-lg font-bold pt-6 pb-2">Artikel 16. Referentie</h3>
            <p tw="text-sm py-2 leading-relaxed">16.1. De klant gaat ermee akkoord dat het door Amiko voor de klant uitgevoerde werk wordt opgenomen in het referentieportfolio van Amiko.</p>
            <h3 tw="text-lg font-bold pt-6 pb-2">Artikel 17. Overmacht</h3>
            <p tw="text-sm py-2 leading-relaxed">17.1. Overmachtsituaties zoals bijvoorbeeld stakingen, publieke onrust, administratieve maatregelen en andere onverwachte gebeurtenissen waarover Amiko geen controle heeft, bevrijden Amiko, voor de duur van de hinder en voor hun draagwijdte, van haar verbintenissen, zonder recht op enige prijsvermindering of schadevergoeding voor de klant.</p>
            <h3 tw="text-lg font-bold pt-6 pb-2">Artikel 18. Nietigheid</h3>
            <p tw="text-sm py-2 leading-relaxed">18.1. Indien enige bepaling van deze algemene voorwaarden nietig is, zullen de overige bepalingen volledig van kracht blijven en zullen Amiko en de klant de nietige bepaling vervangen door een andere bepaling die het doel en de strekking van de nietige bepaling zoveel mogelijk benadert.</p>
            <h3 tw="text-lg font-bold pt-6 pb-2">Artikel 19. Toepasselijk recht – bevoegde rechtbank</h3>
            <p tw="text-sm py-2 leading-relaxed">19.1. Het Belgisch recht is van toepassing op de overeenkomsten van Amiko. Elk geschil met betrekking tot het sluiten, de geldigheid, de uitvoering en/of de beëindiging van deze overeenkomst zal worden beslecht door de bevoegde rechtbanken van het gerechtelijk arrondissement Antwerpen.</p>
            <h3 tw="text-lg font-bold pt-6 pb-2">Artikel 20. De creatie van onderscheidingstekens</h3>
            <p tw="text-sm py-2 leading-relaxed">20.1. Wij hebben zeker de expertise in huis om een woord- of beeldmerk of een complex woord-beeldmerk te ontwikkelen voor u.</p>
            <p tw="text-sm py-2 leading-relaxed">20.2. We willen er u wel op wijzen dat Amiko niet kan instaan voor een onderzoek naar de beschikbaarheid van een teken. We raden u aan contact op te nemen met een gespecialiseerd merkenbureau om na te gaan of bepaalde tekens niet eerder door derden werden gebruikt of geregistreerd als merk.</p>
            <p tw="text-sm py-2 leading-relaxed">20.3. Met ditzelfde gespecialiseerde merkenbureau dient u het onderscheidend vermogen van uw onderscheidingsteken te bespreken nu dergelijke evaluatie gespecialiseerde juridische kennis veronderstelt.</p>
            <p tw="text-sm py-2 leading-relaxed">20.4 Het aankopen van fonts, stockfoto’s en ander grafisch materiaal gebeurt in overleg met de klant en wordt doorgerekend aan de klant. Dit kan gaan over een jaarlijkse of een éénmalige kost.</p>
       </PageLayout>
    );
}